<template>
  <div class="data-container">
    <div class="headerStep">
      <div class="stepOne">
        <span class="num numActive">1</span>
        <span class="text">创建店铺数据</span>
      </div>
      <img v-if="activeIndex == 1" class="arrows" src="./../../../../assets/images/serviceData/xian.png" alt="" />
      <img v-if="activeIndex > 1" class="arrows" src="./../../../../assets/images/serviceData/blue.png" alt="" />
      <div class="stepOne">
        <span :class="activeIndex > 1 ? 'num numActive' : 'num'">2</span>
        <span class="text">创建画像与流量</span>
      </div>
      <img v-if="activeIndex < 3" class="arrows" src="./../../../../assets/images/serviceData/xian.png" alt="" />
      <img v-if="activeIndex > 2" class="arrows" src="./../../../../assets/images/serviceData/blue.png" alt="" />
      <div class="stepOne">
        <span :class="activeIndex > 2 ? 'num numActive' : 'num'">3</span>
        <span class="text">创建商品数据</span>
      </div>
      <img v-if="activeIndex < 4" class="arrows" src="./../../../../assets/images/serviceData/xian.png" alt="" />
      <img v-if="activeIndex > 3" class="arrows" src="./../../../../assets/images/serviceData/blue.png" alt="" />
      <div class="stepOne">
        <span :class="activeIndex > 3 ? 'num numActive' : 'num'">4</span>
        <span class="text">创建客服数据</span>
      </div>
      <img v-if="activeIndex < 5" class="arrows" src="./../../../../assets/images/serviceData/xian.png" alt="" />
      <img v-if="activeIndex > 4" class="arrows" src="./../../../../assets/images/serviceData/blue.png" alt="" />
      <div class="stepOne">
        <span :class="activeIndex > 4 ? 'num numActive' : 'num'">5</span>
        <span class="text">创建成功</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    activeIndex: {
      default: 1,
      type: Number,
    },
  },
};
</script>
<style scoped lang="scss">
.data-container {
  .headerStep {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    .stepOne {
      display: flex;
      flex-direction: column;
      align-items: center;

      .num {
        line-height: 1;
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #d1d2db;
        color: #ffffff;
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        line-height: 40px;
      }

      .numActive {
        background: #1122d8;
      }

      .text {
        line-height: 1;
        color: #878994;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
      }
    }

    .arrows {
      height: 10px;
      margin-bottom: 20px;
    }
  }
}
</style>
