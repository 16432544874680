<template>
  <div class="second-container">
    <el-form style="height: 100%; width: 100%" :model="param" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <span class="common-span">男女比例</span>
      <div class="common-flex">
        <el-form-item label="男" label-width="90px" prop="people_face.ratio.man"> <el-input-number ref="man" :min="1" :max="100" :precision="0" :controls="false" v-model="param.people_face.ratio.man" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="女" label-width="90px" prop="people_face.ratio.woman"> <el-input-number ref="woman" :min="0" :max="100" :precision="0" :controls="false" v-model="param.people_face.ratio.woman" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex" style="margin-top: 25px">
        <span class="title-span">年龄占比</span>
        <span style="margin-left: 10px; color: #2c95ff; cursor: pointer" @click="addAge">添加年龄</span>
      </div>
      <div :class="['common-flex', 'animation']" style="margin-top: 10px" v-for="(item, index) in param.people_face.age" :key="`age_${index}`">
        <el-form-item label-width="80px" :prop="'people_face.age.' + index + '.min'" :rules="rules.min"><el-input-number ref="ageMin" :min="1" :max="120" :precision="0" :controls="false" style="width: 100px" v-model="item.min"></el-input-number> ~</el-form-item>
        <el-form-item label-width="20px" :prop="'people_face.age.' + index + '.max'" :rules="rules.max"><el-input-number ref="ageMax" :min="1" :max="120" :precision="0" :controls="false" style="width: 100px" v-model="item.max"></el-input-number></el-form-item>
        <el-form-item label-width="50px" :prop="'people_face.age.' + index + '.ratio'" :rules="rules.ratio"><el-input-number :min="1" :max="100" :controls="false" style="width: 120px" v-model="item.ratio"></el-input-number> %</el-form-item>
        <i class="iconfont" v-if="![0, 1].includes(index)" style="color: red; margin: 13px 0 0 20px; cursor: pointer" @click="delItem(index)">&#xe650;</i>
      </div>

      <div class="common-flex" style="margin-top: 25px">
        <span class="title-span">城市占比</span>
        <span style="margin-left: 10px; color: #2c95ff; cursor: pointer" @click="dialogVisible = true">选择城市</span>
      </div>
      <div class="test">
        <div :style="`height:${param.city.length > 4 ? '300px' : '100%'};margin-bottom:25px`">
          <div class="common-flex animation" v-for="(item, index) in param.city" :key="`city_${index}`">
            <el-form-item label-width="80px" :prop="'city.' + index + '.ratio'" :rules="rules.city" :label="`${item.name}`"><el-input-number :precision="0" :min="1" :max="100" :controls="false" style="width: 100px" v-model="item.ratio"></el-input-number> %</el-form-item>
          </div>
        </div>
      </div>

      <span class="common-span">促销敏感度</span>
      <div class="common-flex">
        <el-form-item label-width="110px"><span>不敏感</span></el-form-item>
        <el-form-item label-width="145px"><span>轻度敏感</span></el-form-item>
        <el-form-item label-width="140px"><span>中度敏感</span></el-form-item>
        <el-form-item label-width="140px"><span>高度敏感</span></el-form-item>
        <el-form-item label-width="140px"><span>极度敏感</span></el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label-width="80px" prop="people_face.sale.no"><el-input-number :min="1" :max="100" :precision="0" :controls="false" style="width: 100px" v-model="param.people_face.sale.no"></el-input-number> %</el-form-item>
        <el-form-item label-width="80px" prop="people_face.sale.little"><el-input-number :min="1" :max="100" :precision="0" :controls="false" style="width: 100px" v-model="param.people_face.sale.little"></el-input-number> %</el-form-item>
        <el-form-item label-width="80px" prop="people_face.sale.middle"><el-input-number :min="1" :max="100" :precision="0" :controls="false" style="width: 100px" v-model="param.people_face.sale.middle"></el-input-number> %</el-form-item>
        <el-form-item label-width="80px" prop="people_face.sale.high"><el-input-number :min="1" :max="100" :precision="0" :controls="false" style="width: 100px" v-model="param.people_face.sale.high"></el-input-number> %</el-form-item>
        <el-form-item label-width="80px" prop="people_face.sale.best"><el-input-number :min="1" :max="100" :precision="0" :controls="false" style="width: 100px" v-model="param.people_face.sale.best"></el-input-number> %</el-form-item>
      </div>

      <span class="common-span">用户活跃时段</span>
      <div class="common-flex">
        <el-form-item label="1~6时 基础值" label-width="120px" prop="people_face.time.time6.num"> <el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="param.people_face.time.time6.num" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="差值" label-width="60px" prop="people_face.time.time6.difference"> <el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="param.people_face.time.time6.difference" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="7~12时 基础值" label-width="120px" prop="people_face.time.time12.num"> <el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="param.people_face.time.time12.num" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="差值" label-width="60px" prop="people_face.time.time12.difference"> <el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="param.people_face.time.time12.difference" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="13~18时 基础值" label-width="120px" prop="people_face.time.time18.num"> <el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="param.people_face.time.time18.num" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="差值" label-width="60px" prop="people_face.time.time18.difference"> <el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="param.people_face.time.time18.difference" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="18~24时 基础值" label-width="120px" prop="people_face.time.time24.num"> <el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="param.people_face.time.time24.num" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="差值" label-width="60px" prop="people_face.time.time24.difference"> <el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="param.people_face.time.time24.difference" style="width: 120px"></el-input-number> % </el-form-item>
      </div>

      <span class="common-span">客户人群分类</span>
      <div class="common-flex">
        <div v-for="(item, index) in param.people_face.client" :key="`client_${index}`">
          <el-form-item :label="`${index == 0 ? '人群名称' : ''}`" label-width="110px" :prop="'people_face.client.' + index + '.name'" :rules="rules.name"><el-input v-model="item.name" style="width: 90px"></el-input></el-form-item>
          <el-form-item :label="`${index == 0 ? '人群占比' : ''}`" label-width="110px" :prop="'people_face.client.' + index + '.people'" :rules="rules.people"><el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="item.people" style="width: 90px"></el-input-number> % </el-form-item>
          <el-form-item :label="`${index == 0 ? '高价值用户占比' : ''}`" label-width="110px" :prop="'people_face.client.' + index + '.user'" :rules="rules.user"><el-input-number :min="1" :precision="0" :max="100" :controls="false" v-model="item.user" style="width: 90px"></el-input-number> % </el-form-item>
        </div>
      </div>

      <span class="common-span">店铺流量</span>
      <el-form-item label="平均在线时长" label-width="100px" prop="shop_throughput.online.num"><el-input-number :min="0" :max="99999" :controls="false" v-model="param.shop_throughput.online.num" style="width: 90px"></el-input-number></el-form-item>
      <div class="common-flex">
        <el-form-item label="波动值" label-width="100px" prop="shop_throughput.online.low"> <el-input-number ref="onlineLow" :min="-99" :max="100" :precision="0" :controls="false" v-model="param.shop_throughput.online.low" style="width: 130px"></el-input-number> %~ </el-form-item>
        <el-form-item label-width="20px" prop="shop_throughput.online.high"><el-input-number :min="-99" :max="100" :precision="0" :controls="false" v-model="param.shop_throughput.online.high" style="width: 130px"></el-input-number> %</el-form-item>
      </div>

      <div class="btn-flex">
        <el-button class="btn-blue-line-all" @click="goto">上一步</el-button>
        <el-button class="btn-blue" @click="toThird('ruleForm')">下一步</el-button>
      </div>
    </el-form>

    <el-dialog title="选择城市" :visible.sync="dialogVisible" width="50%" @close="closeWindow">
      <el-checkbox-group v-model="checkboxGroup">
        <el-checkbox style="margin-top: 20px" border v-for="(item, index) in cityData" :label="item.name" :key="index">{{ item.name }}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn-blue-line-all" @click="closeWindow">取 消</el-button>
        <el-button class="btn-blue" @click="addTable">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createAnalysisData, getEditAnalysisData } from "@/utils/apis";
import { Message } from "element-ui";
export default {
  data() {
    return {
      dialogVisible: false,
      lock: true,
      cityData: [
        { name: "河北", ratio: 0 },
        { name: "山西", ratio: 0 },
        { name: "辽宁", ratio: 0 },
        { name: "吉林", ratio: 0 },
        { name: "黑龙江", ratio: 0 },
        { name: "江苏", ratio: 0 },
        { name: "浙江", ratio: 0 },
        { name: "安徽", ratio: 0 },
        { name: "福建", ratio: 0 },
        { name: "江西", ratio: 0 },
        { name: "山东", ratio: 0 },
        { name: "河南", ratio: 0 },
        { name: "湖北", ratio: 0 },
        { name: "湖南", ratio: 0 },
        { name: "广东", ratio: 0 },
        { name: "海南", ratio: 0 },
        { name: "四川", ratio: 0 },
        { name: "贵州", ratio: 0 },
        { name: "云南", ratio: 0 },
        { name: "陕西", ratio: 0 },
        { name: "甘肃", ratio: 0 },
        { name: "青海", ratio: 0 },
        { name: "台湾", ratio: 0 },
        { name: "内蒙古", ratio: 0 },
        { name: "广西", ratio: 0 },
        { name: "西藏", ratio: 0 },
        { name: "宁夏", ratio: 0 },
        { name: "新疆", ratio: 0 },
        { name: "北京", ratio: 0 },
        { name: "天津", ratio: 0 },
        { name: "上海", ratio: 0 },
        { name: "重庆", ratio: 0 },
        { name: "香港", ratio: 0 },
        { name: "澳门", ratio: 0 },
        { name: "南海诸岛", ratio: 0 },
      ],
      checkboxGroup: [],
      param: {
        type: "two",
        people_face: {
          ratio: {
            man: void 0,
            woman: void 0,
          },
          age: [
            { min: void 0, max: void 0, ratio: void 0 },
            { min: void 0, max: void 0, ratio: void 0 },
          ],
          city: [],
          sale: {
            no: void 0, //不敏感
            little: void 0, //轻度敏感
            middle: void 0, //中度敏感
            high: void 0, //高度敏感
            best: void 0, //极度敏感
          },
          time: {
            time6: {
              num: void 0, //基础值
              difference: void 0, //差值
            }, //1-6小时 -- 数据格式
            time12: {
              num: void 0, //基础值
              difference: void 0, //差值
            }, //7-12小时 -- 数据格式
            time18: {
              num: void 0, //基础值
              difference: void 0, //差值
            }, //13-18小时 -- 数据格式
            time24: {
              num: void 0, //基础值
              difference: void 0, //差值
            }, //19-24
          },
          client: [
            {
              name: void 0, //人群名称
              people: void 0, //人群占比
              user: void 0, //高价值人群占比
            },
            {
              name: void 0, //人群名称
              people: void 0, //人群占比
              user: void 0, //高价值人群占比
            },
            {
              name: void 0, //人群名称
              people: void 0, //人群占比
              user: void 0, //高价值人群占比
            },
            {
              name: void 0, //人群名称
              people: void 0, //人群占比
              user: void 0, //高价值人群占比
            },
            {
              name: void 0, //人群名称
              people: void 0, //人群占比
              user: void 0, //高价值人群占比
            },
          ],
        },
        city: [],
        shop_throughput: {
          online: {
            num: void 0,
            low: void 0,
            high: void 0,
          },
        },
      },
      rules: {
        min: [{ required: true, message: "请输入年龄下限", trigger: "blur" }],
        max: [{ required: true, message: "请输入年龄上限", trigger: "blur" }],
        ratio: [{ required: true, message: "请输入年龄段占比", trigger: "blur" }],
        name: [{ required: true, min: 1, max: 30, message: "名称1-30个字符", trigger: "blur" }],
        people: [{ required: true, message: "请输入人群占比", trigger: "blur" }],
        user: [{ required: true, message: "请输入高价值用户占比", trigger: "blur" }],
        city: [{ required: true, message: "请输入城市占比", trigger: "blur" }],
        "people_face.ratio.man": [{ required: true, message: "请输入男性比例", trigger: "blur" }],
        "people_face.ratio.woman": [{ required: true, message: "请输入女性比例", trigger: "blur" }],
        "people_face.sale.no": [{ required: true, message: "请输入敏感度", trigger: "blur" }],
        "people_face.sale.little": [{ required: true, message: "请输入敏感度", trigger: "blur" }],
        "people_face.sale.middle": [{ required: true, message: "请输入敏感度", trigger: "blur" }],
        "people_face.sale.high": [{ required: true, message: "请输入敏感度", trigger: "blur" }],
        "people_face.sale.best": [{ required: true, message: "请输入敏感度", trigger: "blur" }],
        "people_face.time.time6.num": [{ required: true, message: "请输入1-6小时基础值", trigger: "blur" }],
        "people_face.time.time6.difference": [{ required: true, message: "请输入1-6小时差值", trigger: "blur" }],
        "people_face.time.time12.num": [{ required: true, message: "请输入7-12小时基础值", trigger: "blur" }],
        "people_face.time.time12.difference": [{ required: true, message: "请输入7-12小时差值", trigger: "blur" }],
        "people_face.time.time18.num": [{ required: true, message: "请输入13-18小时基础值", trigger: "blur" }],
        "people_face.time.time18.difference": [{ required: true, message: "请输入13-18小时差值", trigger: "blur" }],
        "people_face.time.time24.num": [{ required: true, message: "请输入19-24小时基础值", trigger: "blur" }],
        "people_face.time.time24.difference": [{ required: true, message: "请输入19-24小时差值", trigger: "blur" }],
        "shop_throughput.online.num": [{ required: true, message: "请输入平均在线时长", trigger: "blur" }],
        "shop_throughput.online.low": [{ required: true, message: "请输入店铺流量波动值下限", trigger: "blur" }],
        "shop_throughput.online.high": [{ required: true, message: "请输入店铺流量波动值上限", trigger: "blur" }],
      },
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.getEditData();
    }
  },
  watch: {
    "param.people_face.ratio.man"(newValue, oldValue) {
      if ([null, undefined, ""].includes(newValue)) {
        this.param.people_face.ratio.woman = void 0;
      } else if ([100, "100"].includes(newValue)) {
        this.param.people_face.ratio.woman = 0;
      } else {
        this.param.people_face.ratio.woman = 100 - newValue;
      }
    },
  },
  methods: {
    addTable() {
      let arr = this.cityData.reduce((acc, cur) => {
        if (this.checkboxGroup.includes(cur.name)) {
          acc.push(cur);
        }
        return acc;
      }, []);
      this.param.city = arr;
      this.dialogVisible = false;
    },
    closeWindow() {
      this.checkboxGroup = [];
      this.param.city.forEach((item, index) => {
        this.checkboxGroup.push(item.name);
      });
      this.dialogVisible = false;
    },
    validateForm(filed, data, msg, ref) {
      switch (filed) {
        case "sex":
          let sexTotal = Number(data.man + data.woman);
          if (sexTotal !== 100) {
            this.$message.error(msg);
            this.$refs[ref[0]].$el.classList.add("isError");
            this.$refs[ref[1]].$el.classList.add("isError");
            this.lock = false;
            return true;
          } else {
            this.$refs[ref[0]].$el.classList.remove("isError");
            this.$refs[ref[1]].$el.classList.remove("isError");
            this.lock = true;
          }
          break;
        case "age":
          if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].min >= data[i].max) {
                this.$message.error(msg[0]);
                this.$refs[ref][i].$el.classList.add("isError");
                this.lock = false;
                return true;
              } else {
                this.$refs[ref][i].$el.classList.remove("isError");
                this.lock = true;
              }
              let target = data[i + 1];
              if (target) {
                if (data[i].max >= target.min) {
                  this.$message.error(msg[1]);
                  this.$refs[ref][i + 1].$el.classList.add("isError");
                  this.lock = false;
                  return true;
                } else {
                  this.$refs[ref][i + 1].$el.classList.remove("isError");
                  this.lock = true;
                }
              }
            }
            let sum = this.checkData(data);
            if (sum !== 100) {
              this.$message.error(msg[2]);
              this.lock = false;
              return true;
            } else {
              this.lock = true;
            }
          }
          break;
        case "city":
          if (this.param.city.length == 0) {
            this.$message.error("请选择城市");
            this.lock = false;
            return true;
          } else {
            this.lock = true;
          }
          let cityRatio = this.checkData(data);
          if (cityRatio !== 100) {
            Message.closeAll();
            this.$message.error(msg);
            this.lock = false;
            return true;
          } else {
            this.lock = true;
          }
          break;
        case "sale":
          let saleRatio = this.checkData(data);
          if (saleRatio !== 100) {
            Message.closeAll();
            this.$message.error(msg);
            this.lock = false;
            return true;
          } else {
            this.lock = true;
          }
          break;
        case "time":
          let timeSum = Number(data.time6.num + data.time12.num + data.time18.num + data.time24.num);
          if (timeSum != 100) {
            Message.closeAll();
            this.$message.error(msg);
            this.lock = false;
            return true;
          } else {
            this.lock = true;
          }
          break;
        case "client":
          for (let i = 0; i < data.length; i++) {
            for (let j = i + 1; j < data.length; j++) {
              if (data[i].name == data[j].name) {
                Message.closeAll();
                this.$message.error("客户人群分类的名称中有重复数据");
                this.lock = false;
                return true;
              } else {
                this.lock = true;
              }
            }
          }
          let peopleRatio = 0;
          let userRatio = 0;
          data.forEach((item, index) => {
            peopleRatio += Number(item.people);
            userRatio += Number(item.user);
          });
          if (peopleRatio !== 100) {
            Message.closeAll();
            this.$message.error(msg[0]);
            this.lock = false;
            return true;
          } else if (userRatio !== 100) {
            Message.closeAll();
            this.$message.error(msg[1]);
            this.lock = false;
            return true;
          } else {
            this.lock = true;
          }
          break;
        case "online":
          if (data.low > data.high) {
            Message.closeAll();
            this.$message.error(msg);
            this.$refs[ref].$el.classList.add("isError");
            this.lock = false;
            return true;
          } else {
            this.$refs[ref].$el.classList.remove("isError");
            this.lock = true;
          }
          break;
      }
    },
    checkData(data) {
      let sum = 0;
      if (Array.isArray(data)) {
        data.forEach((item, index) => {
          sum += Number(item.ratio);
        });
        return sum;
      } else {
        for (var key in data) {
          sum += Number(data[key]);
        }
        return sum;
      }
    },
    addAge() {
      this.param.people_face.age.push({ min: void 0, max: void 0, ratio: void 0 });
    },
    delItem(index) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "btn-blue",
      })
        .then(() => {
          this.flag = 1;
          this.param.people_face.age = this.param.people_face.age.filter((gItem, gIndex) => gIndex !== index);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.flag = 0;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    toThird(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.validateForm("sex", this.param.people_face.ratio, "男女比例的和应为100%", ["man", "woman"])) {
            return false;
          }
          if (this.validateForm("age", this.param.people_face.age, ["年龄下限必须小于年龄上限", "该年龄的下限只能比上一个年龄段的上限大", "年龄占比的和必须为100"], "ageMin")) {
            return false;
          }
          if (this.validateForm("city", this.param.city, "所有城市占比的和等于100")) {
            return false;
          }
          if (this.validateForm("sale", this.param.people_face.sale, "所有类型的敏感的和等于100")) {
            return false;
          }
          if (this.validateForm("time", this.param.people_face.time, "用户活跃时段的基础值和应为100%")) {
            return false;
          }
          if (this.validateForm("client", this.param.people_face.client, ["人群占比的所有类别的和需要等于100", "高价值用户比例所有类别的和需要等于100"])) {
            return false;
          }
          if (this.validateForm("online", this.param.shop_throughput.online, "在线时长下限值必须小于上限值", "onlineLow")) {
            return false;
          }
          let arrs = Array.from(new Set(this.param.city.concat(this.cityData)));
          arrs.forEach((item, index) => {
            if (!this.checkboxGroup.includes(item.name)) {
              item.ratio = 0;
            }
          });
          this.param.people_face.city = arrs;
          let params = {
            type: this.param.type,
            people_face: this.param.people_face,
            shop_throughput: this.param.shop_throughput,
          };
          if (this.lock) {
            createAnalysisData(params).then((res) => {
              if (res.code === 200) {
                localStorage.setItem("token_two", res.data);
                this.$emit("updateActiviteIndex", 3);
              } else if (res.code === 400) {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    getEditData() {
      let params = {
        id: this.$route.query.id,
      };
      getEditAnalysisData(params)
        .then((res) => {
          if (res.code === 200) {
            this.param.people_face.ratio = res.data.people_face.ratio;
            this.param.people_face.age = res.data.people_face.age;
            this.param.people_face.sale = res.data.people_face.sale;
            this.param.people_face.time = res.data.people_face.time;
            this.param.people_face.client = res.data.people_face.client;
            this.param.shop_throughput = res.data.shop_throughput;
            this.cityData = res.data.people_face.city;
            this.cityData.forEach((item, index) => {
              if (item.ratio !== 0) {
                this.checkboxGroup.push(item.name);
                this.param.city.push(item);
              }
            });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    goto() {
      this.$emit("updateActiviteIndex", 1);
    },
  },
};
</script>

<style lang="scss" scoped>
$checkColor: #1122d8;
$checkborder: #f6f6f3;
::v-deep .el-checkbox__inner {
  border: none;
  display: none;
}
::v-deep .el-checkbox.is-bordered {
  border-color: $checkborder;
  background: $checkborder;
}
::v-deep .el-checkbox.is-bordered.is-checked {
  border-color: $checkColor;
  color: $checkColor;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: $checkColor;
}
::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
  margin-bottom: 0px;
}

@import "./Analysis";
@include inputError;
.second-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 55px;
  display: flex;
  @include validateForm;
}
.common-span {
  font-size: 20px;
  display: block;
  margin: 20px 0;
}
.title-span {
  margin-left: 10px;
  font-size: 16px;
  line-height: 20px;
}
.common-flex {
  @include InputFlex;
}
.btn-flex {
  margin-left: 40%;
}
.test {
  width: 17%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
}

@keyframes toright {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
.animation {
  animation: toright 0.5s ease 0s;
}
::-webkit-scrollbar {
  display: none;
}
</style>
