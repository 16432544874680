<template>
  <div class="fouth-container">
    <el-form style="height: 100%; width: 100%" :model="param" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="common-flex">
        <el-form-item label="三分钟回复率" label-width="120px" prop="service_data.three.num"><el-input-number :min="1" :max="100" :precision="2" :controls="false" v-model="param.service_data.three.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label="比较上月" label-width="90px" prop="service_data.three.month"><el-input-number :precision="2" :controls="false" v-model="param.service_data.three.month" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="平均人工响应时长" label-width="130px" prop="service_data.average_user.hour"> <el-input-number :min="0" controls-position="right" v-model="param.service_data.average_user.hour" style="width: 120px"></el-input-number> 时</el-form-item>
        <el-form-item label-width="30px" prop="service_data.average_user.minute"> <el-input-number :min="0" :max="59" controls-position="right" v-model="param.service_data.average_user.minute" style="width: 120px"></el-input-number> 分 </el-form-item>
        <el-form-item label-width="30px" prop="service_data.average_user.second"> <el-input-number :min="0" :max="59" controls-position="right" v-model="param.service_data.average_user.second" style="width: 120px"></el-input-number> 秒 </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="上月平均响应时长" label-width="130px" prop="service_data.average_month.hour"> <el-input-number :min="0" :max="100" controls-position="right" v-model="param.service_data.average_month.hour" style="width: 120px"></el-input-number> 时</el-form-item>
        <el-form-item label-width="30px" prop="service_data.average_month.minute"> <el-input-number :min="0" :max="59" controls-position="right" v-model="param.service_data.average_month.minute" style="width: 120px"></el-input-number> 分 </el-form-item>
        <el-form-item label-width="30px" prop="service_data.average_month.second"> <el-input-number :min="0" :max="59" controls-position="right" v-model="param.service_data.average_month.second" style="width: 120px"></el-input-number> 秒 </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="客服服务投诉率" label-width="120px" prop="service_data.complaint.num"> <el-input-number :min="1" @blur="showServiceData" :max="100" :precision="2" :controls="false" v-model="param.service_data.complaint.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label="比较上月" label-width="90px" prop="service_data.complaint.month"> <el-input-number :precision="2" :controls="false" v-model="param.service_data.complaint.month" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="客服满意度" label-width="120px" prop="service_data.satisfaction.num"> <el-input-number :min="1" :max="100" :precision="2" :controls="false" v-model="param.service_data.satisfaction.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label="比较上月" label-width="90px" prop="service_data.satisfaction.month"> <el-input-number :precision="2" :controls="false" v-model="param.service_data.satisfaction.month" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="响应流失客户率" label-width="120px" prop="service_data.run.num"> <el-input-number :min="1" :max="100" :precision="2" :controls="false" v-model="param.service_data.run.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label="比较上月" label-width="90px" prop="service_data.run.month"> <el-input-number :precision="2" :controls="false" v-model="param.service_data.run.month" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="咨询下单量" label-width="120px" prop="service_data.buy.num"> <el-input-number :min="1" :max="100" @blur="showServiceData" :precision="2" :controls="false" v-model="param.service_data.buy.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label="比较上月" label-width="90px" prop="service_data.buy.month"> <el-input-number :precision="2" :controls="false" v-model="param.service_data.buy.month" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="客户回访率" label-width="120px" prop="service_data.interview.num"> <el-input-number :min="1" :max="100" :precision="2" :controls="false" v-model="param.service_data.interview.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label="比较上月" label-width="90px" prop="service_data.interview.month"> <el-input-number :precision="2" :controls="false" v-model="param.service_data.interview.month" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="客单价比例" label-width="120px" prop="service_data.price"> <el-input-number :min="1" @blur="showServiceData" :max="100" :controls="false" v-model="param.service_data.price" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="客单价=" label-width="70px"
          ><span>{{ price ? price + "￥" : "" }}</span></el-form-item
        >
      </div>
      <div class="common-flex" style="margin-top: 20px">
        <el-form-item label="售前客服数" label-width="120px" prop="service_data.new_service"> <el-input-number @blur="showServiceData" :min="1" :max="100" :controls="false" v-model="param.service_data.new_service" style="width: 120px"> </el-input-number></el-form-item>
        <el-form-item label="售后客服数" style="margin-left: 50px" label-width="90px" prop="service_data.old_service">
          <el-input-number :min="1" :max="100" :controls="false" v-model="param.service_data.old_service" @blur="showServiceData" style="width: 120px"></el-input-number>
        </el-form-item>
      </div>
      <div>
        <el-table :data="param.service_data.service" border style="width: 100%; margin-top: 20px" height="480" size="medium" :header-cell-style="{ fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA' }" :cell-style="{ fontSize: '14px', color: '#343441' }">
          <el-table-column prop="name" label="客服账号" width="120px" align="center"> </el-table-column>
          <el-table-column prop="new" label="售前咨询人数" align="center" width="130px">
            <template slot-scope="scope">
              <template v-if="scope.row.type == 'new'">
                <el-input-number :controls="false" v-model="scope.row.new" style="width: 90px"></el-input-number>
              </template>
              <template v-else>
                <span>——</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="old" label="售后咨询人数" align="center" width="130px">
            <template slot-scope="scope">
              <template v-if="scope.row.type == 'old'">
                <el-input-number :controls="false" v-model="scope.row.old" style="width: 90px"></el-input-number>
              </template>
              <template v-else>
                <span>——</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="order" label="订单量" align="center" width="130px">
            <template slot-scope="scope">
              <template v-if="scope.row.type == 'new'">
                <el-input-number @blur="calculation(scope.row)" :min="0" :max="9999999999" v-model="scope.row.order" :controls="false" style="width: 90px"></el-input-number>
              </template>
              <template v-else>
                <span>——</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="money" label="客服销售额(￥)" align="center" width="120px">
            <template slot-scope="scope">
              <span>{{ scope.row.type == "new" ? scope.row.money + "￥" : "——" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="three" label="3分钟内未 回复人数" align="center" width="150px">
            <template slot-scope="scope">
              <el-input-number @blur="compare(scope.row, 'three')" :min="0" :max="999999999" :controls="false" style="width: 120px" v-model="scope.row.three"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="three_ratio" label="3分钟内回复率" align="center" width="120px">
            <template slot-scope="scope">
              <span>{{ scope.row.three_ratio ? scope.row.three_ratio.toFixed(2) + "%" : 0 + "%" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="average_user" label="平均响应时长" align="center" width="120px">
            <template slot-scope="scope">
              <el-input-number :min="0" :max="86400" :controls="false" style="width: 90px" v-model="scope.row.average_user"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column prop="dispute" label="纠纷退款人数" width="125px" align="center">
            <template slot-scope="scope">
              <template v-if="scope.row.type == 'old'">
                <el-input-number @blur="compare(scope.row, 'dispute')" :min="0" :max="999999999" :controls="false" style="width: 90px" v-model="scope.row.dispute"></el-input-number>
              </template>
              <template v-else>
                <span>——</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="complaint" :label="`投诉数 (总投诉人数为${complaintNum ? complaintNum : 0}人)`" align="center">
            <template slot-scope="scope">
              <el-input-number @blur="compare(scope.row, 'complaint')" :controls="false" style="width: 120px" v-model="scope.row.complaint"></el-input-number>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="btn-flex" style="margin-top: 20px">
        <el-button class="btn-blue-line-all" @click="goto">上一步</el-button>
        <el-button class="btn-blue" @click="toFourh('ruleForm')">下一步</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { createAnalysisData, createService, getPrice, getEditAnalysisData } from "@/utils/apis";
export default {
  data() {
    return {
      orderTotal: 0,
      complaintTemp: [],
      price: 0,
      param: {
        type: "four",
        service_data: {
          three: {
            num: void 0, //回复率
            month: void 0, //同比上月
          }, //三分钟回复
          average_user: {
            hour: void 0, //时
            minute: void 0, //分
            second: void 0, //秒
          }, //平均人工响应时长
          average_month: {
            hour: void 0, //时
            minute: void 0, //分
            second: void 0, //秒
          }, //上月平均响应时长
          complaint: {
            num: void 0, //投诉率
            month: void 0, //同比上月
          }, //客服服务投诉率
          satisfaction: {
            num: void 0, //满意度
            month: void 0, //同比上月
          }, //客服满意度
          run: {
            num: void 0, //流失率
            month: void 0, //同比上月
          }, //响应流失客户率
          buy: {
            num: void 0, //订单占比
            month: void 0, //同比上月
          }, //咨询下单量
          interview: {
            num: void 0, //回访率 %
            month: void 0, //同比上月
          }, //客户回访率
          price: void 0, //客单价 %
          old_service: void 0, //售后客服数
          new_service: void 0, //售前客服数
          service: [], //客服数据
        },
      },
      complaintNum: 0,
      rules: {
        "service_data.three.num": [{ required: true, message: "请输入三分钟回复率", trigger: "blur" }],
        "service_data.three.month": [{ required: true, message: "请输入比较上月数值", trigger: "blur" }],
        "service_data.average_user.hour": [{ required: true, message: "请输入", trigger: "blur" }],
        "service_data.average_user.minute": [{ required: true, message: "请输入", trigger: "blur" }],
        "service_data.average_user.second": [{ required: true, message: "请输入", trigger: "blur" }],
        "service_data.average_month.hour": [{ required: true, message: "请输入", trigger: "blur" }],
        "service_data.average_month.minute": [{ required: true, message: "请输入", trigger: "blur" }],
        "service_data.average_month.second": [{ required: true, message: "请输入", trigger: "blur" }],
        "service_data.complaint.num": [{ required: true, message: "请输入投诉率", trigger: "blur" }],
        "service_data.complaint.month": [{ required: true, message: "请输入比较上月数值", trigger: "blur" }],
        "service_data.satisfaction.num": [{ required: true, message: "请输入满意度", trigger: "blur" }],
        "service_data.satisfaction.month": [{ required: true, message: "请输入比较上月数值", trigger: "blur" }],
        "service_data.run.num": [{ required: true, message: "请输入响应流失客户率", trigger: "blur" }],
        "service_data.run.month": [{ required: true, message: "请输入比较上月数值", trigger: "blur" }],
        "service_data.buy.num": [{ required: true, message: "请输入咨询下单量", trigger: "blur" }],
        "service_data.buy.month": [{ required: true, message: "请输入比较上月数值", trigger: "blur" }],
        "service_data.interview.num": [{ required: true, message: "请输入客户回访率", trigger: "blur" }],
        "service_data.interview.month": [{ required: true, message: "请输入比较上月数值", trigger: "blur" }],
        "service_data.price": [{ required: true, message: "请输入客单价比例", trigger: "blur" }],
        "service_data.old_service": [{ required: true, message: "请输入售后客服数", trigger: "blur" }],
        "service_data.new_service": [{ required: true, message: "请输入售前客服数", trigger: "blur" }],
      },
    };
  },
  props: ["getData", "oldArr"],
  watch: {
    "param.service_data.price"(newValue, oldValue) {
      if (![undefined, null, ""].includes(newValue)) {
        this.showPrice();
      }
    },
  },
  activated() {
    if (this.$route.query.id) {
      this.getEditData();
    }
    this.showPrice();
  },

  methods: {
    goto() {
      this.$emit("updateActiviteIndex", 3);
    },
    showServiceData() {
      if (this.param.service_data.new_service && this.param.service_data.old_service && this.param.service_data.complaint.num && this.param.service_data.buy.num && this.param.service_data.price) {
        let newArr = new Array(this.getData.visit, this.getData.money, this.getData.service_num, 
        this.getData.service_old, this.getData.service_new, this.param.service_data.new_service, 
        this.param.service_data.old_service, this.param.service_data.price, this.param.service_data.buy.num, 
        this.param.service_data.complaint.num);
        let result = this.oldArr.sort().toString() === newArr.sort().toString();
        switch (result) {
          case false:
            this.$emit("updateOldArr", newArr);
            this.getNewTable();
            break;
        }
      }
    },
    getNewTable() {
      let params = {
        visit: this.getData.visit,
        money: this.getData.money,
        service_num: this.getData.service_num,
        service_old: this.getData.service_old,
        service_new: this.getData.service_new,
        old_service: this.param.service_data.old_service,
        new_service: this.param.service_data.new_service,
        complaint: this.param.service_data.complaint.num,
        buy: this.param.service_data.buy.num,
        price: this.param.service_data.price,
      };
      createService(params)
        .then((res) => {
          this.orderTotal = 0;
          res.data.data.forEach((item, index) => {
            this.orderTotal += Number(item.order);
          });
          this.param.service_data.service = res.data.data;
          this.param.service_data.service.forEach((item, index) => {
            item.complaint = this.complaintTemp[index];
          });
          this.complaintNum = res.data.complaintNum;
          this.calcPrice();
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    showPrice() {
      if (this.getData.service_num && this.param.service_data.buy.num && this.param.service_data.price) {
        let params = {
          visit: this.getData.visit,
          money: this.getData.money,
          service_num: this.getData.service_num,
          buy: this.param.service_data.buy.num,
          price: this.param.service_data.price,
        };
        getPrice(params)
          .then((res) => {
            if (res.code === 200) {
              this.price = res.data;
            }
          })
          .catch((err) => {
            this.$message.error(err);
          });
      }
    },
    validateForm(data, msg, filed) {
      switch (filed) {
        case "service":
          let after = 0;
          let before = 0;
          let complaint = 0;
          data.forEach((item, index) => {
            after += Number(item.new);
            before += Number(item.old);
            complaint += Number(item.complaint);
          });
          let serviceNum = this.getData.service_num / 100;
          let serviceOld = this.getData.service_old / 100;
          let serviceNew = this.getData.service_new / 100;
          let calc = Math.ceil(this.getData.visit * serviceNum);
          let oldTotal = Math.floor(calc * serviceOld);
          let newTotal = Math.ceil(calc * serviceNew);
          if (newTotal !== after) {
            this.$message.error(`${msg[0] + newTotal}`);
            return true;
          }
          if (oldTotal !== before) {
            this.$message.error(`${msg[1] + oldTotal}`);
            return true;
          }
          if (Number(this.complaintNum) !== complaint) {
            this.$message.error(msg[2]);
            return true;
          }
          break;
        case "order":
          let curTotal = 0;
          data.forEach((item, index) => {
            curTotal += Number(item.order);
          });
          if (this.orderTotal != curTotal) {
            this.$message.error(msg + `${this.orderTotal}`);
            return true;
          }
          break;
      }
    },
    compare(item, filed) {
      switch (filed) {
        case "three":
          if (item.type == "new") {
            if (item.three > item.new) {
              this.$message.error("3分钟内未回复人数必须小于等于售前咨询人数");
              item.three = 0;
            } else {
              item.three_ratio = ((item.new - item.three) / item.new).toFixed(4) * 100;
            }
          } else if (item.type == "old") {
            if (item.three > item.old) {
              this.$message.error("3分钟内未回复人数必须小于等于售后咨询人数");
              item.three = 0;
            } else {
              item.three_ratio = ((item.old - item.three) / item.old).toFixed(4) * 100;
            }
          }
          break;
        case "dispute":
          if (item.type == "new") {
            if (item.dispute > item.new) {
              this.$message.error("纠纷退款人数必须小于等于售前咨询人数");
              item.dispute = 0;
            }
          } else if (item.type == "old") {
            if (item.dispute > item.old) {
              this.$message.error("纠纷退款人数必须小于等于售后咨询人数");
              item.dispute = 0;
            }
          }
          break;
        case "complaint":
          if (item.type == "new") {
            if (item.complaint > item.new) {
              this.$message.error("投诉人数必须小于等于售前咨询人数");
              item.complaint = 0;
            }
          } else if (item.type == "old") {
            if (item.complaint > item.old) {
              this.$message.error("投诉人数必须小于等于售后咨询人数");
              item.complaint = 0;
            }
          }
          break;
      }
    },
    calculation(item) {
      item.money = (item.order * Number(item.price).toFixed(2)).toFixed(2);
    },
    calcPrice() {
      if ((this.param.service_data.service??'')!='') {
        this.param.service_data.service.forEach((item, index) => {
          item.money = (item.order * Number(item.price).toFixed(2)).toFixed(2);
        });
      }
    },
    getEditData() {
      let params = {
        id: this.$route.query.id,
      };
      getEditAnalysisData(params)
        .then((res) => {
          if (res.code === 200) {
            this.complaintNum = 0;
            this.orderTotal=0;
            this.param.service_data = res.data.service_data;
            res.data.service_data.service.forEach((item, index) => {
              this.complaintTemp.push(item.complaint);
              this.complaintNum += Number(item.complaint);
              this.orderTotal += Number(item.order);
            });
            this.showServiceData();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    toFourh(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.validateForm(this.param.service_data.service, ["售前咨询人数所有值的和必须等于", "售后咨询人数所有值的和必须等于", "所有投诉数的和要等于总投诉人数"], "service")) {
            return false;
          }
          if (this.validateForm(this.param.service_data.service, "订单量总和应为", "order")) {
            return false;
          }
          let params = {
            type: this.param.type,
            token_one: localStorage.getItem("token_one"),
            token_two: localStorage.getItem("token_two"),
            token_three: localStorage.getItem("token_three"),
            service_data: this.param.service_data,
          };
          createAnalysisData(params).then((res) => {
            if (res.code === 200) {
              this.$emit("updateActiviteIndex", 5);
              this.$message.success(res.msg);
            } else if (res.code === 400) {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Analysis";
@include inputError;
.fouth-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 55px;
  display: flex;
  @include validateForm;
  ::-webkit-scrollbar {
    display: none;
  }
}
.common-flex {
  @include InputFlex;
}
.btn-flex {
  @include btnFlex;
}
</style>
