<template>
  <div class="third-container">
    <el-form style="height: 100%; width: 100%" :model="param" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <div class="common-flex">
        <el-form-item label="商品访客数" label-width="120px" prop="goods_data.goods_visit.num"> <el-input-number :min="1" :max="9999999999" :controls="false" v-model="param.goods_data.goods_visit.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label="比较上月" label-width="90px" prop="goods_data.goods_visit.month"> <el-input-number :precision="2" :controls="false" v-model="param.goods_data.goods_visit.month" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="差值" label-width="70px" prop="goods_data.goods_visit.difference"> <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="param.goods_data.goods_visit.difference" style="width: 120px"></el-input-number> % </el-form-item>
      </div>
      <div class="common-flex">
        <el-form-item label="商品浏览量" label-width="120px" prop="goods_data.goods_browse.num"> <el-input-number ref="browse" :min="1" :max="9999999999" :controls="false" v-model="param.goods_data.goods_browse.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label="比较上月" label-width="90px" prop="goods_data.goods_browse.month"> <el-input-number :precision="2" :controls="false" v-model="param.goods_data.goods_browse.month" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="差值" label-width="70px" prop="goods_data.goods_browse.difference"> <el-input-number :min="1" :max="100" :precision="0" :controls="false" v-model="param.goods_data.goods_browse.difference" style="width: 120px"></el-input-number> % </el-form-item>
      </div>

      <div class="common-flex">
        <span class="common-span">店铺流量</span>
        <span style="margin-left: 10px; line-height: 65px; color: #2c95ff; cursor: pointer" @click="open">选择商品</span>
      </div>

      <div class="goods" v-if="param.goods_data.goods_data.length > 0">
        <div v-for="(item, index) in param.goods_data.goods_data" :key="index">
          <div class="goods-main animation">
            <div class="goods-content">
              <div class="goods-box">
                <div class="goods-imgs">
                  <img style="width: 86%; height: 83%; border-radius: 5px" :src="item.img" />
                </div>
                <div class="goods-desc">
                  <span class="news_info">{{ item.goods_name }}</span>
                  <span style="color: red">单价:{{ item.price }}</span>
                  <span>分类:{{ item.category }}</span>
                </div>
              </div>
              <i class="iconfont" style="color: red; margin-left: 10px; cursor: pointer" @click="delItem(item.goods_id)">&#xe650;</i>
            </div>
            <div class="goods-watch">
              <span>商品销售量</span>
              <el-input-number :min="1" :max="100" :precision="0" :controls="false" style="width: 120px" v-model="item.sale"></el-input-number>
              <span style="margin-left: 5px">%</span>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-flex btn-bottom">
        <el-button class="btn-blue-line-all" @click="goto">上一步</el-button>
        <el-button class="btn-blue" @click="toFourh('ruleForm')">下一步</el-button>
      </div>
    </el-form>
    <!-- 选择商品 -->
    <el-dialog title="选择商品" :visible.sync="dialogVisible" style="overflow-x: hidden" width="600px" @close="closeWindow">
      <span class="label">行业分类: </span>
      <el-select v-model="categoryId" placeholder="请选择行业分类" clearable @change="selectChange">
          <el-option v-for="item in classifyOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <div class="goods-window">
        <el-checkbox-group class="common-group" v-model="checkedCities">
          <el-checkbox style="margin-top: 20px" v-for="(item, index) in goodsList" :key="index" border :label="item.goods_id">
            <div class="goods-box">
              <img style="width: 50px; height: 50px" :src="item.goods_image" />
              <div>
                <span class="title">{{ item.goods_name }}</span>
                <span style="color: red">单价:{{ item.goods_price }}</span>
              </div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="btn-blue-line-all" @click="closeWindow">取 消</el-button>
        <el-button class="btn-blue" @click="addTable">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAnalysisGoodsData, createAnalysisData, getEditAnalysisData } from "@/utils/apis";
export default {
  data() {
    return {
      dialogVisible: false,
      goodsList: [],
      checkedCities: [],
      categoryId:'',
      classifyOptions:[],
      param: {
        type: "three",
        goods_data: {
          goods_browse: {
            num: void 0, //访客数
            month: void 0, //同比上月
            difference: void 0, //差值
          },
          goods_visit: {
            num: void 0, //浏览数
            month: void 0, //同比上月
            difference: void 0, //差值
          },
          goods_data: [],
        },
      },
      
      tempTable:[],
      rules: {
        "goods_data.goods_browse.num": [{ required: true, message: "请输入商品浏览量", trigger: "blur" }],
        "goods_data.goods_browse.month": [{ required: true, message: "请输入比较上月数值", trigger: "blur" }],
        "goods_data.goods_browse.difference": [{ required: true, message: "请输入差值", trigger: "blur" }],
        "goods_data.goods_visit.num": [{ required: true, message: "请输入商品访客数", trigger: "blur" }],
        "goods_data.goods_visit.month": [{ required: true, message: "请输入比较上月数值", trigger: "blur" }],
        "goods_data.goods_visit.difference": [{ required: true, message: "请输入差值", trigger: "blur" }],
      },
    };
  },
  
  mounted() {
    this.getCategoryList();
    this.allRequest();
  },
  methods: {
    open(){
      this.dialogVisible = true
      this.selectChange()
    },
    getCategoryList(){
      this.$http.axiosGet(this.$api.categoryList, res=>{
          if(res.code === 200){
              this.classifyOptions = res.data
          } else {
              this.$message.warning(res.msg)
          }
      })
    },
   async selectChange(){
      let params = {
          paging: 0,
        };
        this.categoryId?params.category_id=this.categoryId:''
        let res = await getAnalysisGoodsData(params);
        this.goodsList = res.data.list;
    },
    allRequest() {
      let arr = [this.getGoodsData()];
      if (this.$route.query.id) {
        arr.push(this.getEditData());
      }
      Promise.all(arr)
        .then((res) => {
          this.goodsList = res[0].data.list;
          this.tempTable=res[0].data.list;
          if (res[1]) {
            this.param.goods_data.goods_browse = res[1].data.goods_data.goods_browse;
            this.param.goods_data.goods_visit = res[1].data.goods_data.goods_visit;
            res[1].data.goods_data.goods_data.forEach((item, index) => {
              this.checkedCities.push(item.goods_id);
            });
            this.param.goods_data.goods_data = res[1].data.goods_data.goods_data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getGoodsData() {
      try {
        let params = {
          paging: 0,
        };
        this.categoryId?params.category_id=this.categoryId:''
        let res = await getAnalysisGoodsData(params);
        return res;
      } catch (error) {
        console.log(error);
      }
    },
    closeWindow() {
      this.checkedCities = [];
      this.param.goods_data.goods_data.forEach((item, index) => {
        this.checkedCities.push(item.goods_id);
      });
      this.dialogVisible = false;
      this.categoryId=""
    },
    addTable() {
      let arr = this.tempTable.reduce((acc, cur) => {
        if (this.checkedCities.includes(cur.goods_id)) {
          acc.push({
            goods_id: cur.goods_id,
            goods_name: cur.goods_name,
            price: cur.goods_price,
            category: cur.category_name,
            img: cur.goods_image,
            sale: void 0,
          });
        }
        return acc;
      }, []);
      // if(this.categoryId){
      //   // let tempArr=this.param.goods_data.goods_data.concat(arr)
      //   // const cacheTable=this.checkedCities.map(item=>this.tempTable.find(tItem=>tItem.goods_id==item))
      //  let resTable= this.tempTable.reduce((acc,cur)=>{
      //     if(this.checkedCities.includes(cur.goods_id)){
      //       acc.push({
      //         goods_id: cur.goods_id,
      //         goods_name: cur.goods_name,
      //         price: cur.goods_price,
      //         category: cur.category_name,
      //         img: cur.goods_image,
      //         sale: void 0,
      //       });
      //     }
      //     return acc
      //   },[])
      //   this.param.goods_data.goods_data=resTable
      //   // let newArr=[]
      //   // for(let i=0;i<tempArr.length;i++){
      //   //   console.log(tempArr[i])
      //   //   if(newArr.indexOf(tempArr[i].goods_id)==-1){
      //   //     newArr.push(tempArr[i].goods_id)
      //   //   }
      //   // }
      //   // const temp=newArr.map(item=>tempArr.find(fItem=>item==fItem.goods_id))
      //   // this.param.goods_data.goods_data=this.checkedCities.map(cItem=>temp.find(tItem=>cItem==tItem.goods_id))
      // }else{
      //   this.param.goods_data.goods_data = arr;
      // }
      this.param.goods_data.goods_data = arr;
      this.dialogVisible = false;
    },
    validateForm(one, two, msg) {
      if (one < two) {
        this.$message.error(msg);
        this.$refs["browse"].$el.classList.add("isError");
        return true;
      } else {
        this.$refs["browse"].$el.classList.remove("isError");
      }
    },
    checkTotal(data) {
      if (this.param.goods_data.goods_data.length == 0) {
        this.$message.error("请选择商品");
        return true;
      }
      let total = 0;
      data.forEach((item, index) => {
        total += item.sale;
      });
      if (total != 100) {
        this.$message.error("商品数据的商品销售量占比和应为100%");
        return true;
      }
    },
    delItem(id) {
      this.$confirm("此操作将删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "btn-blue",
      })
        .then(() => {
          this.param.goods_data.goods_data = this.param.goods_data.goods_data.filter((gItem) => gItem.goods_id !== id);
          this.checkedCities = this.checkedCities.filter((cItem) => cItem !== id);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    toFourh(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.validateForm(this.param.goods_data.goods_browse.num, this.param.goods_data.goods_visit.num, "商品浏览量必须大于商品访客数")) {
            return false;
          }
          if (this.checkTotal(this.param.goods_data.goods_data)) {
            return false;
          }
          let params = {
            type: this.param.type,
            goods_data: this.param.goods_data,
          };
          createAnalysisData(params)
            .then((res) => {
              if (res.code === 200) {
                this.$emit("updateActiviteIndex", 4);
                localStorage.setItem("token_three", res.data);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        } else {
          return false;
        }
      });
    },
    async getEditData() {
      let params = {
        id: this.$route.query.id,
      };
      let result = await getEditAnalysisData(params);
      return result;
    },
    goto() {
      this.$emit("updateActiviteIndex", 2);
    },
  },
};
</script>

<style lang="scss" scoped>
$checkColor: #1122d8;
::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
  margin-left: 0px;
}
::-webkit-scrollbar {
  display: none;
}
::v-deep .el-checkbox.is-bordered {
  height: 70px;
}
::v-deep .el-checkbox__inner {
  margin-bottom: 35px;
}
::v-deep .el-checkbox.is-bordered.is-checked {
  border-color: $checkColor;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: $checkColor;
  border-color: $checkColor;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: $checkColor;
}
@import "./Analysis";
@include inputError;
.third-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 55px;
  display: flex;
  @include validateForm;
  .goods {
    height: 100%;
    margin-left: 9%;
    &-main {
      display: flex;
      height: 150px;
      flex-direction: column;
      .goods-content {
        height: 100%;
        align-items: center;
        display: flex;
        .goods-box {
          height: 100%;
          width: 30%;
          display: flex;
          background: #ffffff;
          border: 1px solid #dcdfe6;
          border-radius: 5px;
          .goods-imgs {
            display: flex;
            align-items: center;
            width: 20%;
            height: 100%;
            padding-left: 10px;
          }
          .goods-desc {
            display: flex;
            justify-content: center;
            padding-left: 10px;
            flex-direction: column;
            height: 100%;
          }
        }
      }
      .goods-watch {
        margin: 10px 0 10px 0;
        display: flex;
        align-items: center;
        span {
          margin-right: 15px;
        }
      }
    }
  }
}
.goods-window {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20px;
}
.title {
  width: 7rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.news_info {
  width: 6.24rem;
  font-size: 14px;
  color: #333333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.common-flex {
  @include InputFlex;
}
.btn-flex {
  @include btnFlex;
}
.common-span {
  font-size: 20px;
  display: block;
  margin: 20px 0 0 45px;
}
.btn-bottom {
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.common-group {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 9.5%;
}
.goods-box {
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 10px;
    }
  }
}
@keyframes toright {
  0% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}
.animation {
  animation: toright 0.5s ease 0s;
}
</style>
