<template>
  <div class="first-container">
    <el-form :model="param" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="数据名称" prop="name">
        <el-input v-model="param.name" style="width: 420px"></el-input>
      </el-form-item>

      <div class="common-flex">
        <el-form-item label="店铺关注数" prop="shop_situation.watch.num">
          <el-input-number ref="follow" :controls="false" :min="1" :max="9999999999" v-model="param.shop_situation.watch.num" style="width: 200px"></el-input-number>
        </el-form-item>
        <el-form-item label="比较上月" prop="shop_situation.watch.month"> <el-input-number :controls="false" :precision="2" v-model="param.shop_situation.watch.month" style="width: 120px"> </el-input-number> % </el-form-item>
      </div>

      <div class="common-flex">
        <el-form-item label="新增关注用户" prop="shop_situation.new.num">
          <el-input-number :controls="false" :min="1" :max="9999999999" v-model="param.shop_situation.new.num" style="width: 200px"></el-input-number>
        </el-form-item>
        <el-form-item label="比较上月" prop="shop_situation.new.month"> <el-input-number :controls="false" :precision="2" v-model="param.shop_situation.new.month" style="width: 120px"> </el-input-number> % </el-form-item>
      </div>

      <div class="common-flex" style="margin-top: 10px">
        <el-form-item label="浏览量" prop="shop_situation.browse.num">
          <el-input-number ref="browse" :controls="false" :min="1" :max="9999999999" v-model="param.shop_situation.browse.num" style="width: 120px"></el-input-number>
        </el-form-item>
        <el-form-item label="比较上月" prop="shop_situation.browse.month"> <el-input-number :controls="false" :precision="2" v-model="param.shop_situation.browse.month" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="老客" prop="shop_situation.browse.old"> <el-input-number ref="browseold" :controls="false" :min="1" :max="100" v-model="param.shop_situation.browse.old" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="新客" prop="shop_situation.browse.new"> <el-input-number ref="browsenew" :controls="false" :min="1" :max="100" v-model="param.shop_situation.browse.new" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="差值" prop="shop_situation.browse.difference"> <el-input-number :controls="false" :min="1" :max="100" :precision="0" v-model="param.shop_situation.browse.difference" style="width: 90px"></el-input-number> % </el-form-item>
      </div>

      <div class="common-flex">
        <el-form-item label="访问量" prop="shop_situation.visit.num"> <el-input-number :controls="false" :min="1" :max="9999999999" v-model="param.shop_situation.visit.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label="比较上月" prop="shop_situation.visit.month"> <el-input-number :controls="false" :precision="2" v-model="param.shop_situation.visit.month" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="老客" prop="shop_situation.visit.old"> <el-input-number ref="visitold" :controls="false" :min="1" :max="100" v-model="param.shop_situation.visit.old" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="新客" prop="shop_situation.visit.new"> <el-input-number ref="visitnew" :controls="false" :min="1" :max="100" v-model="param.shop_situation.visit.new" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="差值" prop="shop_situation.visit.difference"> <el-input-number :controls="false" :min="1" :max="100" :precision="0" v-model="param.shop_situation.visit.difference" style="width: 90px"></el-input-number> % </el-form-item>
      </div>

      <div class="common-flex" style="margin-top: 10px">
        <el-form-item label="客服月咨询人数比例" prop="shop_situation.service.num" label-width="140px">
          <el-input-number :controls="false" :min="1" :max="100" :precision="0" v-model="param.shop_situation.service.num" style="width: 140px"></el-input-number>
        </el-form-item>
        <el-form-item label="比较上月" prop="shop_situation.service.month"> <el-input-number :controls="false" :precision="2" v-model="param.shop_situation.service.month" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="售前" prop="shop_situation.service.old"> <el-input-number ref="serviceNew" :controls="false" :min="1" :max="100" v-model="param.shop_situation.service.new" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="售后" prop="shop_situation.service.new"> <el-input-number ref="serviceOld" :controls="false" :min="1" :max="100" v-model="param.shop_situation.service.old" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="售前差值" prop="shop_situation.service.old_difference"> <el-input-number :controls="false" :min="1" :max="100" :precision="0" v-model="param.shop_situation.service.old_difference" style="width: 120px"></el-input-number> % </el-form-item>
        <el-form-item label="售后差值" prop="shop_situation.service.new_difference"> <el-input-number :controls="false" :min="1" :max="100" :precision="0" v-model="param.shop_situation.service.new_difference" style="width: 120px"></el-input-number> % </el-form-item>
      </div>

      <div class="common-flex">
        <el-form-item label="UV每日量" label-width="120px" prop="shop_situation.uv.num"> <el-input-number :controls="false" :min="1" :max="9999999999" v-model="param.shop_situation.uv.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label-width="90px" label="波动值" prop="shop_situation.uv.low"> <el-input-number ref="uv" :controls="false" :min="-99" :max="100" v-model="param.shop_situation.uv.low" style="width: 120px"></el-input-number> %~</el-form-item>
        <el-form-item style="margin-left: 1px" label-width="10px" prop="shop_situation.uv.high"> <el-input-number :controls="false" :min="-99" :max="100" v-model="param.shop_situation.uv.high" style="width: 120px"></el-input-number> % </el-form-item>
      </div>

      <div class="common-flex" style="margin-top: 30px">
        <el-form-item label="成交用户数" label-width="120px" prop="shop_situation.user.num"> <el-input-number :controls="false" :min="1" :max="9999999999" v-model="param.shop_situation.user.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label-width="90px" label="比较上月" prop="shop_situation.user.month"> <el-input-number :controls="false" :precision="2" v-model="param.shop_situation.user.month" style="width: 120px"></el-input-number> %</el-form-item>
      </div>

      <div class="common-flex">
        <el-form-item label="成交订单量" label-width="120px" prop="shop_situation.order.num"> <el-input-number ref="order" :controls="false" :min="1" :max="9999999999" v-model="param.shop_situation.order.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label-width="90px" label="比较上月" prop="shop_situation.order.month"> <el-input-number :controls="false" :precision="2" v-model="param.shop_situation.order.month" style="width: 120px"></el-input-number> %</el-form-item>
        <el-form-item label-width="90px" label="差值" prop="shop_situation.order.difference"> <el-input-number :controls="false" :precision="0" :min="1" :max="100" v-model="param.shop_situation.order.difference" style="width: 120px"></el-input-number> %</el-form-item>
      </div>

      <div class="common-flex">
        <el-form-item label="成交金额" label-width="120px" prop="shop_situation.money.num"> <el-input-number :controls="false" :min="1" :max="9999999999.99" :precision="2" v-model="param.shop_situation.money.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label-width="90px" label="比较上月" prop="shop_situation.money.month"> <el-input-number :controls="false" :precision="2" v-model="param.shop_situation.money.month" style="width: 120px"></el-input-number> %</el-form-item>
        <el-form-item label-width="90px" label="差值" prop="shop_situation.money.difference"> <el-input-number :controls="false" :precision="0" :min="1" :max="100" v-model="param.shop_situation.money.difference" style="width: 120px"></el-input-number> %</el-form-item>
      </div>

      <div class="common-flex">
        <el-form-item label="本月复购率" label-width="120px" prop="shop_situation.repeat.num"> <el-input-number :controls="false" :min="1" :max="100" :precision="2" v-model="param.shop_situation.repeat.num" style="width: 120px"></el-input-number></el-form-item>
        <el-form-item label-width="90px" label="比较上月" prop="shop_situation.repeat.month"> <el-input-number :controls="false" :precision="2" v-model="param.shop_situation.repeat.month" style="width: 120px"></el-input-number> %</el-form-item>
      </div>
      <div class="btn-flex">
        <el-button class="btn-blue-line-all" @click="goto">上一步</el-button>
        <el-button class="btn-blue" @click="toSecond('ruleForm')">下一步</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { createAnalysisData, getEditAnalysisData } from "@/utils/apis";
import { Message } from "element-ui";
export default {
  data() {
    return {
      lock: true,
      oldArr: [],
      param: {
        type: "one",
        name: void 0,
        shop_situation: {
          watch: { num: void 0, month: void 0 },
          new: { num: void 0, month: void 0 },
          browse: { num: void 0, month: void 0, old: void 0, new: void 0, difference: void 0 },
          visit: { num: void 0, month: void 0, old: void 0, new: void 0, difference: void 0 },
          service: { num: void 0, month: void 0, old: void 0, new: void 0, old_difference: void 0, new_difference: void 0 },
          uv: { num: void 0, low: void 0, high: void 0 },
          user: { num: void 0, month: void 0 },
          order: { num: void 0, month: void 0, difference: void 0 },
          money: { num: void 0, month: void 0, difference: void 0 },
          repeat: { num: void 0, month: void 0 },
        },
      },
      rules: {
        name: [
          { required: true, message: "请输入数据名称", trigger: "blur" },
          { min: 2, max: 50, message: "长度在 2 到 50 个字符", trigger: "blur" },
        ],
        "shop_situation.watch.num": [{ required: true, message: "请输入店铺关注数", trigger: "blur" }],
        "shop_situation.watch.month": [{ required: true, message: "请输入比较上月的数值", trigger: "blur" }],
        "shop_situation.new.num": [{ required: true, message: "新增关注用户数", trigger: "blur" }],
        "shop_situation.new.month": [{ required: true, message: "请输入比较上月的数值", trigger: "blur" }],
        "shop_situation.browse.num": [{ required: true, message: "请输入浏览量", trigger: "blur" }],
        "shop_situation.browse.month": [{ required: true, message: "请输入比较上月的数值", trigger: "blur" }],
        "shop_situation.browse.old": [{ required: true, message: "请输入老客", trigger: "blur" }],
        "shop_situation.browse.new": [{ required: true, message: "请输入新客", trigger: "blur" }],
        "shop_situation.browse.difference": [{ required: true, message: "请输入差值", trigger: "blur" }],
        "shop_situation.visit.num": [{ required: true, message: "请输入访问量", trigger: "blur" }],
        "shop_situation.visit.month": [{ required: true, message: "请输入比较上月的数值", trigger: "blur" }],
        "shop_situation.visit.old": [{ required: true, message: "请输入老客", trigger: "blur" }],
        "shop_situation.visit.new": [{ required: true, message: "请输入新客", trigger: "blur" }],
        "shop_situation.visit.difference": [{ required: true, message: "请输入差值", trigger: "blur" }],
        "shop_situation.service.num": [{ required: true, message: "请输入月咨询人数比例", trigger: "blur" }],
        "shop_situation.service.month": [{ required: true, message: "请输入比较上月的数值", trigger: "blur" }],
        "shop_situation.service.old": [{ required: true, message: "请输入售前", trigger: "blur" }],
        "shop_situation.service.new": [{ required: true, message: "请输入售后", trigger: "blur" }],
        "shop_situation.service.old_difference": [{ required: true, message: "请输入售前差值", trigger: "blur" }],
        "shop_situation.service.new_difference": [{ required: true, message: "请输入售后差值", trigger: "blur" }],
        "shop_situation.uv.num": [{ required: true, message: "请输入UV每日量", trigger: "blur" }],
        "shop_situation.uv.low": [{ required: true, message: "请输入波动值", trigger: "blur" }],
        "shop_situation.uv.high": [{ required: true, message: "请输入波动值", trigger: "blur" }],
        "shop_situation.user.num": [{ required: true, message: "请输入成交用户数", trigger: "blur" }],
        "shop_situation.user.month": [{ required: true, message: "请输入比较上月的数值", trigger: "blur" }],
        "shop_situation.order.num": [{ required: true, message: "请输入成交订单量", trigger: "blur" }],
        "shop_situation.order.month": [{ required: true, message: "请输入比较上月的数值", trigger: "blur" }],
        "shop_situation.order.difference": [{ required: true, message: "请输入差值", trigger: "blur" }],
        "shop_situation.money.num": [{ required: true, message: "请输入成交金额", trigger: "blur" }],
        "shop_situation.money.month": [{ required: true, message: "请输入比较上月的数值", trigger: "blur" }],
        "shop_situation.money.difference": [{ required: true, message: "请输入差值", trigger: "blur" }],
        "shop_situation.repeat.num": [{ required: true, message: "请输入本月复购率", trigger: "blur" }],
        "shop_situation.repeat.month": [{ required: true, message: "请输入比较上月的数值", trigger: "blur" }],
      },
    };
  },

  mounted() {
    if (this.$route.query.id) {
      this.getEditData();
    }
  },

  watch: {
    "param.shop_situation.browse.old"(newValue, oldValue) {
      if ([null, undefined, ""].includes(newValue)) {
        this.param.shop_situation.browse.new = void 0;
      } else if ([100, "100"].includes(newValue)) {
        this.param.shop_situation.browse.new = 0;
      } else {
        this.param.shop_situation.browse.new = 100 - newValue;
      }
    },
    "param.shop_situation.visit.old"(newValue, oldValue) {
      if ([null, undefined, ""].includes(newValue)) {
        this.param.shop_situation.visit.new = void 0;
      } else if ([100, "100"].includes(newValue)) {
        this.param.shop_situation.visit.new = 0;
      } else {
        this.param.shop_situation.visit.new = 100 - newValue;
      }
    },
    "param.shop_situation.service.new"(newValue, oldValue) {
      if ([null, undefined, ""].includes(newValue)) {
        this.param.shop_situation.service.old = void 0;
      } else if ([100, "100"].includes(newValue)) {
        this.param.shop_situation.service.old = 0;
      } else {
        this.param.shop_situation.service.old = 100 - newValue;
      }
    },
  },
  methods: {
    validate(checkOne, checkTwo, msg, filed) {
      switch (filed) {
        case "follow":
          return this.checkData(checkOne, checkTwo, msg, filed);
        case "browse":
          if (checkOne > checkTwo) {
            Message.closeAll();
            this.$message.error(msg);
            this.$refs[filed].$el.classList.add("isError");
            this.lock = false;
            return true;
          } else if (checkOne > 0.1 * checkTwo) {
            Message.closeAll();
            this.$message.error(`${msg}，并且访问量不能超过浏览量的10%`);
            this.lock = false;
            return true;
          } else {
            this.$refs[filed].$el.classList.remove("isError");
            this.lock = true;
          }
          break;
        case "newold":
          let browseTotal = Number(checkOne.new + checkOne.old);
          let visitTotal = Number(checkTwo.new + checkTwo.old);
          if (browseTotal !== 100) {
            Message.closeAll();
            this.$message.error(msg[0]);
            this.$refs["browseold"].$el.classList.add("isError");
            this.$refs["browsenew"].$el.classList.add("isError");
            this.lock = false;
            return true;
          } else {
            this.$refs["browseold"].$el.classList.remove("isError");
            this.$refs["browsenew"].$el.classList.remove("isError");
            this.lock = true;
          }
          if (visitTotal !== 100) {
            Message.closeAll();
            this.$message.error(msg[1]);
            this.$refs["visitold"].$el.classList.add("isError");
            this.$refs["visitnew"].$el.classList.add("isError");
            this.lock = false;
            return true;
          } else {
            this.$refs["visitold"].$el.classList.remove("isError");
            this.$refs["visitnew"].$el.classList.remove("isError");
            this.lock = true;
          }
          break;
        case "service":
          let serviceTotal = Number(checkOne + checkTwo);
          if (serviceTotal !== 100) {
            Message.closeAll();
            this.$message.error(msg);
            this.$refs["serviceOld"].$el.classList.add("isError");
            this.$refs["serviceNew"].$el.classList.add("isError");
            this.lock = false;
            return true;
          } else {
            this.$refs["serviceOld"].$el.classList.remove("isError");
            this.$refs["serviceNew"].$el.classList.remove("isError");
            this.lock = true;
          }
          break;
        case "uv":
          let visitSum = Math.ceil(this.param.shop_situation.visit.num / 30);
          let uvHigh = Math.ceil(this.param.shop_situation.uv.num * (this.param.shop_situation.uv.high / 100 + 1));
          if (uvHigh > visitSum) {
            Message.closeAll();
            this.$message.error("UV每日量要小于访问量");
            this.lock = false;
            return true;
          }
          return this.checkData(checkOne, checkTwo, msg, filed);

        case "order":
          if (checkOne >= checkTwo) {
            this.$refs[filed].$el.classList.remove("isError");
            this.lock = true;
            return false;
          } else if (checkOne < checkTwo) {
            this.$refs[filed].$el.classList.add("isError");
            Message.closeAll();
            this.$message.error(msg);
            this.lock = false;
            return true;
          }
          break;
        default:
          break;
      }
    },
    checkData(validateOne, validateTwo, msg, ref) {
      if (validateOne > validateTwo) {
        Message.closeAll();
        this.$message.error(msg);
        this.$refs[ref].$el.classList.add("isError");
        this.lock = false;
        return true;
      } else {
        this.$refs[ref].$el.classList.remove("isError");
        this.lock = true;
      }
    },

    toSecond(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.validate(this.param.shop_situation.new.num, this.param.shop_situation.watch.num, "店铺关注数必须大于新增关注数", "follow")) {
            return false;
          }
          if (this.validate(this.param.shop_situation.visit.num, this.param.shop_situation.browse.num, "浏览量必须大于访问量", "browse")) {
            return false;
          }
          if (this.validate(this.param.shop_situation.browse, this.param.shop_situation.visit, ["浏览量的新老客总比例之和应为100%", "访问量的新老客总比例之和应为100%"], "newold")) {
            return false;
          }
          if (this.validate(this.param.shop_situation.service.old, this.param.shop_situation.service.new, "1客服月咨询人数的售前/售后总比例之和应为100%", "service")) {
            return false;
          }
          if (this.validate(this.param.shop_situation.uv.low, this.param.shop_situation.uv.high, "UV波动值的上限必须大于下限", "uv")) {
            return false;
          }
          if (this.validate(this.param.shop_situation.order.num, this.param.shop_situation.user.num, "成交订单量必须大于等于成交用户数", "order")) {
            return false;
          }
          let params = {
            type: this.param.type,
            name: this.param.name,
            shop_situation: this.param.shop_situation,
          };
          if (this.$route.query.id) {
            params.id = this.$route.query.id;
          }
          if (this.lock) {
            createAnalysisData(params)
              .then((res) => {
                if (res.code === 200) {
                  let obj = {
                    visit: this.param.shop_situation.visit.num,
                    money: this.param.shop_situation.money.num,
                    service_num: this.param.shop_situation.service.num,
                    service_old: this.param.shop_situation.service.old,
                    service_new: this.param.shop_situation.service.new,
                  };
                  this.$emit("saveData", obj);
                  this.$emit("saveOldArr", this.oldArr);
                  localStorage.setItem("token_one", res.data);
                  this.$emit("updateActiviteIndex", 2);
                } else if (res.code === 400) {
                  Message.closeAll();
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {});
          }
        } else {
          return false;
        }
      });
    },
    getEditData() {
      let params = {
        id: this.$route.query.id,
      };
      getEditAnalysisData(params)
        .then((res) => {
          if (res.code === 200) {
            this.param.name = res.data.name;
            this.param.shop_situation = JSON.parse(JSON.stringify(res.data.shop_situation));
            this.oldArr.push(res.data.shop_situation.visit.num, res.data.shop_situation.money.num, 
            res.data.shop_situation.service.num, res.data.shop_situation.service.old, 
            res.data.shop_situation.service.new,res.data.service_data.new_service,
            res.data.service_data.old_service,res.data.service_data.price,
            res.data.service_data.buy.num,res.data.service_data.complaint.num);
            this.$emit("saveOldArr", this.oldArr);
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    goto() {
      this.$router.push("/admin/dataAnalysis/manager");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Analysis";
@include inputError;
.first-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 55px;
  display: flex;
  @include validateForm;
}
.common-flex {
  @include InputFlex;
}
.btn-flex {
  @include btnFlex;
}
</style>
